<template>
    <div class="infromBox">
        <div class="top" :style="{height:bannerHeight+'rem'}">
            <img src="https://images.innocomn.com/5c4a420210226172650727.jpg?imageView2/2/w/4096/q/100!|imageslim" alt="">
        </div>
        <div class="informContent" :style="{top:bannerHeight - 0.25+'rem'}">
            <div class="informcon">
                <div class="inforleft">
                    <img src="https://images.innocomn.com/5c4a420210226172650727.jpg?imageView2/2/w/4096/q/100!|imageslim" alt="">
                </div>
                <div class="inforright">
                    <div class="top"><span>Y-Jade</span><img class="img" @click="editClick()" src="../../assets/otherTU/edit.png"></div>
                    <div
                     class="bottom"><span style="color:#888">公司:</span> 行邦通讯<span style="margin-left:20px;"><span style="color:#888">身份:</span> 参会代表</span></div>
                </div>
            </div>
        </div>
        <div class="detailsBox">
                <ul v-for="(item,index) in modeData" :key="index">
                    <div class="imgBox">
                    <img :src="item.img">
                    </div>
                    <span>{{item.title}}</span>
                </ul>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            bannerHeight:null,
            modeData:[
                {
                    img:require("../../assets/otherTU/mode.png"),
                    title:"视频列表"
                },
                {
                    img:require("../../assets/otherTU/mode.png"),
                    title:"视频列表"
                },
                  {
                    img:require("../../assets/otherTU/mode.png"),
                    title:"视频列表"
                },
                {
                    img:require("../../assets/otherTU/mode.png"),
                    title:"视频列表"
                },
                {
                    img:require("../../assets/otherTU/mode.png"),
                    title:"视频列表"
                },
                {
                    img:require("../../assets/otherTU/mode.png"),
                    title:"视频列表"
                },
            ]
        }
    },
    methods:{
        editClick(){
        }
    },
    mounted(){
        let width = document.body.clientWidth;
        this.bannerHeight = ((width / 16 * 9)/100).toFixed(1);
    }
}
</script>
<style scoped lang="less">
    .infromBox{
        width: 100%;
        height: 100vh;
        background: #eee;
        .top{
            width: 100%;
            position: relative;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .informContent{
            width: 94%;
            height: 100px;
            background: #fff;
            position: absolute;
            left:3%;
            border-radius: 3px;
            .informcon{
                width: 100%;
                display: flex;
                .inforleft{
                    width: 100px;
                    height: 100px;
                    display: flex;
                    align-items: center; /*垂直居中*/
                    justify-content: center; /*水平居中*/
                    img{
                        width: 65px;
                        height: 65px;
                        border-radius: 50%;
                    }
                }
                .inforright{
                    padding: 10px;
                    .top{
                        height: 40px;
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 40px;
                        .img{
                            width: 12px;
                            height: 15px;
                            margin-left: 15px;
                        }
                  
                    }
                    .bottom{
                        font-size: 14px;
                        height: 40px;
                        line-height: 30px;
                    }
                }
            }
        }
        .detailsBox{
            width: 94%;
            margin:0 auto;
            margin-top: 70px;
            font-size: 14px;
            overflow:wrap;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            ul{
                width: 105px;
                height:105px;
                background: #fff;
                border-radius: 3px;
                margin-top: 20px;
                .imgBox{
                    width: 105px;
                    height: 65px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                }
                img{
                    width: 40px;
                }
                span{
                    display: block;
                    text-align: center;
                }
            }
        }
    }
</style>